import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

import { capitalise, truncateAst } from '../util/'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PostContent from '../components/post-content'
import { H2 } from '../components/typography'

const CategoryTemplate = ({ data, pageContext, className }) => {
  const { posts, coverImages } = data
  const { name, description } = pageContext

  return (
    <Layout title={name} titleLink={`/${name}`} className={className}>
      <SEO
        title={capitalise(name)}
        description={description}
      />
      <section className="max-w-3xl mx-auto px-4 py-12">
        {posts.edges.map(({ node }) => {
          const coverImage = coverImages.edges.find(image => node.fields.slug === `/${ image.node.relativeDirectory }`)
          return (
            <article key={node.fields.slug} className="mb-6">
              <H2 className="mb-4">{node.frontmatter.title}</H2>
              <Image
                fluid={coverImage.node.childImageSharp.fluid}
                className={`w-full mb-2`}
              />
              <PostContent content={truncateAst(node.excerptAst, 'p', 3)} className="mb-4" />
              <Link
                to={node.fields.slug}
                className="font-display underline"
              >
                Read full post &rarr;
              </Link>
            </article>
          )
        })}
      </section>
    </Layout>
  )
}

export default styled(CategoryTemplate)``

export const pageQuery = graphql`
  query ($contentRegex: String!) {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $contentRegex } }
      sort: { fields: [frontmatter___date, frontmatter___priority], order: DESC }
    ) {
      edges {
        node {
          excerptAst(pruneLength: 1000)
          fields {
            slug
          }
          frontmatter {
            dates
            title
            description
          }
        }
      }
    }
    coverImages: allFile(
      filter: { absolutePath: { regex: "//content/.*/cover-image/" } }
    ) {
      edges {
        node {
          absolutePath
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
